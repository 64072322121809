.container {
    display: flex;
    justify-content: center;
    align-items: flex-start; /* Align items to the start */
    height: calc(100vh - 200px); /* Subtract 200px from the height */
    flex-wrap: wrap;
    margin-top: 100px; /* Add top margin */
  }
  
  .tile {
    width: 200px;
    height: 200px;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    background-color: #f1f1f1;
    padding: 4px;
  }
  
  .tile-content {
    text-align: center;
    color: black;
  }
  