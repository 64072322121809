.modal {
  position: fixed;
  left: 50%;
  top: 49%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 16px;
  border: 2px solid black;
  z-index: 3;
  width: 80%; /* Adjusts width based on viewport */
  max-width: 800px; /* Maximum width constraint */
  display: flex;
  flex-direction: column;
  max-height: 90vh; /* Limits height to 90% of the viewport height */
}

.modalHeader {
  margin-top: -8px;
  padding-bottom: 4px;
}

.modalBody {
  overflow-y: auto; /* Adds vertical scrollbar if content overflows */
  flex-grow: 1; /* Takes up all available space */
  /* font-size: 14px; */
}

.row {
  display: flex;
  justify-content: space-between; /* Distributes space between inputs */
  margin-bottom: 8px;
  flex-wrap: wrap; /* Allows items to wrap in smaller screens if necessary */
}

.inputGroup {
  display: flex;
  flex-direction: column;
  flex: 1; /* Allows each group to expand and share available space */
  min-width: 120px; /* Ensures that input doesn't get too narrow on smaller screens */
  margin-right: 10px; /* Adds spacing between input groups */
}

.inputGroup input, .inputGroup select, .textareaGroup textarea {
  padding: 8px;
  margin: 4px 4px 4px 0;
  /* font-size: 14px; */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.textareaGroup {
  width: 100%; /* Ensures the textarea group takes the full width of the modal */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; /* Keeps consistent spacing */
}

.buttonsContainer {
  display: flex;
  justify-content: space-between; /* Space between left and right buttons */
  align-items: flex-start; /* Aligns items to the top */
}

.leftButtons {
  display: flex; /* Keeps Update and New buttons in a row */
}

.button {
  margin-right: 10px; /* Optional: Space between buttons */
}

.newButton {
  background-color: green;
  color: white;
}

.updateButton {
  background-color: grey;
  color: white;
}

.shiftRight {
  margin-left: auto; /* Pushes the New button to the right */
}


.cancelButton2 {
  margin-left: auto; /* Ensures the Cancel button is on the right */
  background: red;
  border: none;
  font-size: 24px; /* Adjust size */
  cursor: pointer;
  color: #333; /* Change color as needed */
}


label {
  font-weight:500;
}