.confirmation-page {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    padding: 16px;
    gap: 16px;
    text-align: center; /* Center the heading */
    margin-top: 70px; /* Optional: Adjust as needed to position content from the top */
  }
  
  .confirmation-page h2 {
    margin: 0;
  }
  
  .confirmation-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-width: 292px; /* Limit the width for better readability */
    text-align: left; /* Ensure content inside is left-aligned */
    margin: 24px auto 40px auto; /* Center the confirmation-details horizontally */
  }
  
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px; /* Space between label and value */
    padding: 0;
  }
  
  .label {
    flex: 1;
    font-weight: bold;
    text-align: left;
    white-space: nowrap; /* Prevent labels from wrapping */
  }
  
  .value {
    flex: 2;
    text-align: left;
  }
  
  .confirmation-page .back-button {
    background-color: #6b46c1;
    color: white;
    padding: 10px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  