.license-form {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    font-family: Arial, sans-serif;
    margin-top: 70px;
  }
  
  .license-form .content {
    display: flex;
    gap: 16px;
    flex: 1;
    overflow: hidden; /* Ensure content does not overflow */
  }
  
  .license-form .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .license-form .section {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
  }
  
  .license-form .section h3 {
    margin: 0 0 12px;
  }
  
  .license-form .input-field {
    width: 98%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .license-form .date-inputs {
    display: flex;
    gap: 16px;
  }

  .license-form .sku-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow-y: auto; /* Enable vertical scrolling */
    max-height: calc(100vh - 250px); /* Adjust based on available height */
  }
  
  .license-form .sku-item {
    display: flex;
    justify-content: space-between;
  }
  
  .license-form .quantity-input {
    width: 100px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
  
  .license-form .footer {
    padding: 16px;
    background-color: white;
    border-top: 1px solid #ddd;
    display: flex;
    justify-content: center;
  }
  
  .license-form .submit-button {
    background-color: #6b46c1;
    color: white;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }

  .license-form .submit-button {
    background-color: #6b46c1;
    color: white;
    padding: 12px 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .license-form .submit-button.disabled {
    background-color: #cccccc; /* Gray background */
    cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
  }

 
  .history-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .license-history-header,
  .license-history-item {
    display: flex;
    justify-content: space-between; /* Distribute columns evenly */
    padding: 8px 0;
  }
  
  .license-history-header {
    font-weight: bold;
    background-color: #f3f3f3; /* Light background for header */
    border-bottom: 1px solid #ddd;
  }
  
  .license-history-item {
    background-color: #ffffff; /* White background for rows */
    border-bottom: 1px solid #ddd;
  }
  
  .license-history-item:nth-child(even) {
    background-color: #f9f9f9; /* Alternate background for better readability */
  }
  
  .column {
    flex: 1; /* Allow columns to take equal space */
    padding: 4px 8px; /* Add padding for better spacing */
  }
  
  .column.header {
    text-align: left; /* Align header text to the left */
  }
  
  .copy-link {
    background-color: transparent;
    color: #6b46c1;
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .copy-link:hover {
    color: #5939a1; 
  }
  