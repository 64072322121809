.titleBar {
    width: 100%;
    min-height: 38px;
    padding: 10px;
    color: white;
    text-align: center;
    position: fixed;
    top: 0;
    z-index: 1;
    display: flex;
    align-items: center;
  }
  
  .titleBar.default {
    background-color: #003366;
  }
  
  .titleBar.lightPurple {
    background-color: #6b46c1; /* Light purple background */
  }
  
  .link {
    text-decoration: none;
    margin-left: 4px;
    display: flex;
    align-items: center;
  }
  
  .icon {
    vertical-align: middle;
  }
  