.listContainer {
    width: 33.33%;
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100%;
    background-color: transparent; /* Keeps the background transparent */
    border-right: 2px solid #ccc; /* Adds a border only on the right side */
  }
  
  .buttonContainer {
    display: flex;
    justify-content: flex-end; /* Aligns button to the right */
    width: 100%; /* Ensures the container stretches across the list container */
  }
  
  .addPatientButton {
    padding: 8px 16px;
    cursor: pointer;
    margin: 10px 0; /* Adds vertical margin */
    background-color: royalblue;
    color: white;
    border: none;
    border-radius: 4px;
    width: 148px;
  }